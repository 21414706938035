import React from 'react';
import RehypeReact from 'rehype-react';
import PropTypes from 'prop-types';

import SEO from './seo';
import VerifyEmailForm from '../components/Common/VerifyEmailForm/VerifyEmailForm';
import Review from '../components/Review/Review';
import Slider from '../components/Slider/Slider';

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    'verify-email-form': VerifyEmailForm,
    review: Review,
    slider: Slider
  }
}).Compiler;

const IndexPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { title },
      htmlAst
    }
  } = data;

  return (
    <>
      <SEO
        title={title}
        keywords={[
          'email',
          'check',
          'online',
          'service',
          'mail validation',
          'email verification',
          'bulk api email verification'
        ]}
      />
      {renderAst(htmlAst)}
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object
};

export default IndexPage;
